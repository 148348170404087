import {useQuery} from "~/composables/useQuery";
import {ProductType, ProspectLoanType} from "~/enums/ProductType";
import {FALLBACK_BANKS_AMOUNT} from "~/service/constants";
import {MeetingType} from "~/enums/MeetingType";
import {capitalize} from "lodash-es";
import type {LendiQuery, MeetingOrVerificationCustomerInterest} from "~/models/UTMs/UTMs";
import {Offer} from "~/models/Offer";

export const useConsultationQuery = () => {
    const {query,} = useQuery<LendiQuery>();
    const bankStore =  useBanksStore();

    const offer: Offer = {
        loanPeriod: query.loanPeriod,
        bank: query.bank,
        installment: query.installment,
        rrso: query.rrso,
        loanAmount: query.loanAmount
    }
    const creditWorthiness = query.creditWorthiness
    const prospectLoanType: ProspectLoanType =  capitalize(query.utm_loan_category) as ProspectLoanType || ProspectLoanType.MORTGAGE;
    const productType: ProductType = ProductType[`${prospectLoanType.toUpperCase()}`] || ProductType.MORTGAGE;
    const agentId: number | undefined = query.utm_owner;
    const meetingType: MeetingType = query.type;
    const isValidMeetingType: boolean = query.meetingType && Object.values(MeetingType).includes(query.meetingType)
    const customerInterest: MeetingOrVerificationCustomerInterest = query.customerInterest;
    /** Its our way to distinct if the view/calculator/widget is in /lendigety */
    const isLendiget = !!query.utm_owner;

    const banksAmount = computed<number>(() => {
        return bankStore.products[prospectLoanType.toLowerCase()].length || FALLBACK_BANKS_AMOUNT;
    });

    return {
        agentId,
        meetingType,
        productType,
        prospectLoanType,
        banksAmount,
        isLendiget,
        isValidMeetingType,
        customerInterest,
        offer,
        creditWorthiness,
    };
};
